import React from 'react';
import styled from 'styled-components';

type PopTileProps = {
  alt: string;
  image: string;
  url: string;
};

const PopTile: React.SFC<PopTileProps> = ({ image, alt, url }) => {
  const Link = styled.a``;
  const Tile = styled.div`
    color: white;
    text-align: center;
    transition: 0.3s ease-in-out;
    position: relative;
    &:hover,
    &:active,
    &:focus {
      transform: scale(1.03);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
      z-index: 9999;
    }
    padding-bottom: 0;
    line-height: 0;
  `;
  const Image = styled.img`
    border-radius: 5px;
    width: 100%;
  `;
  return (
    <div>
      <Link href={url} target="_blank">
        <Tile>
          <Image src={image} alt={alt} />
        </Tile>
      </Link>
    </div>
  );
};

export default PopTile;
