/**
 * Constants with the relevant 'election issues' to be displayed in the WhyVoteGreen section.
 * Somewhat deprecated, hasn't been used in recent elections, as we needed more flexibility.
 */

import Fed25Health from '../../assets/images/fe2025-1_health.png';
import Fed25Housing from '../../assets/images/fe2025-2_housing.png';
import Fed25Enviro from '../../assets/images/fe2025-3_enviro.png';
import Fed25Costs from '../../assets/images/fe2025-4_costs.png';
import Vic22WVGIntegrity from '../../assets/images/htv_vic22_integrity.png';
import Vic22WVGRenewables from '../../assets/images/htv_vic22_renewables.png';
import Vic22WVGRent from '../../assets/images/htv_vic22_rentcaps.png';
import Nsw23WVGCoal from '../../assets/images/nsw23_endcoal.png';
import Nsw23WVGPokies from '../../assets/images/nsw23_pokies.png';
import Nsw23WVGRent from '../../assets/images/nsw23_rents.png';

const nsw23 = [
  {
    alt: 'End coal & gas by 2030',
    image: Nsw23WVGCoal,
    url: 'https://greens.org.au/nsw/climatechange2023',
  },
  {
    alt: 'Freeze & control rents, & ban unfair evictions',
    image: Nsw23WVGRent,
    url: 'https://greens.org.au/nsw/makehousingaffordable2023',
  },
  {
    alt: 'Pull the pin on pokies',
    image: Nsw23WVGPokies,
    url: 'https://greens.org.au/sites/default/files/2022-12/INITIATIVE_PULL_THE_PIN_ON_POKIES-1.pdf',
  },
];

const fed25 = [
  {
    alt: 'Dental into Medicare, See the GP for Free',
    image: Fed25Health,
    url: 'https://greens.org.au/health',
  },
  {
    alt: 'Fighting for Renters &amp; First Home Buyers',
    image: Fed25Housing,
    url: 'https://greens.org.au/housing',
  },
  {
    alt: 'Strong Climate &amp; Environment Action',
    image: Fed25Enviro,
    url: 'https://greens.org.au/climate',
  },
  {
    alt: 'Tackle the Cost of Living',
    image: Fed25Costs,
    url: 'https://greens.org.au/cost-of-living',
  },
]

export default {
  // fed22,
  // vic22,
  nsw23,
  fed25
};
