import React from 'react';
import { Container } from 'react-bootstrap';
import ElectionIssues from '~/helpers/constants/ElectionIssues';

import { PopGrid } from '@australiangreens/components';

/**
 * Component for the 2025 federal election "Why Vote Green" section.
 */
export const Fed25WVG = () => (
  <Container>
    <div className="container pt-1">
      <div className="d-none d-md-inline">
        <p>If you&lsquo;re worried about the cost of living, housing, or climate crisis, you&lsquo;re not alone.</p>
        <p>We can&lsquo;t keep voting for the same two parties and expecting a different result.</p>
        <p>The Greens are within reach of winning new seats across the country.</p>
      </div>
      <p><strong>With a minority government, the Greens will keep Dutton out and get Labor to act.</strong></p>
      <p>If you want change, the first step is to vote for it.</p>
      <p><strong>Vote 1 Greens.</strong></p>

      <PopGrid tiles={ElectionIssues.fed25} rowSize={4} />
    </div>
  </Container>
);

export default Fed25WVG;
